import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "rounded-lg bg-white p-2.5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "mr-10 flex items-center" }
const _hoisted_4 = { class: "text-sm font-medium" }
const _hoisted_5 = { class: "flex max-w-screen-lg w-full" }
const _hoisted_6 = { value: "" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { value: "" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { value: "" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { value: "" }
const _hoisted_13 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_Field = _resolveComponent("Field")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiIcon, {
            class: "mr-2",
            name: "filter"
          }),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('FILTER_TITLE')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Field, {
            class: "flex-1 mr-2.5 border",
            id: "company",
            name: "company",
            as: "select"
          }, {
            default: _withCtx(() => [
              _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t('FILTER_INPUT_PLACEHOLDER_CLIENTS')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyFilter, (company) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: company.id,
                  value: company.id
                }, _toDisplayString(company.name), 9, _hoisted_7))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_Field, {
            class: "flex-1 mr-2.5 border",
            id: "residence",
            name: "residence",
            as: "select"
          }, {
            default: _withCtx(() => [
              _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.$t('FILTER_INPUT_PLACEHOLDER_RESIDENCE')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.residenceFilter, (residence) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: residence.id,
                  value: residence.id
                }, _toDisplayString(residence.build), 9, _hoisted_9))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_Field, {
            class: "flex-1 mr-2.5 border",
            id: "manager",
            name: "manager",
            as: "select"
          }, {
            default: _withCtx(() => [
              _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t('FILTER_INPUT_PLACEHOLDER_MANAGER')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.managerFilter, (manager) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: manager.id,
                  value: manager.id
                }, _toDisplayString(manager.firstname + ' '+ manager.lastname), 9, _hoisted_11))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_Field, {
            class: "flex-1 mr-2.5 border",
            id: "guardian",
            name: "guardian",
            as: "select"
          }, {
            default: _withCtx(() => [
              _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t('FILTER_INPUT_PLACEHOLDER_GUARDIAN')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guardianFilter, (guardian) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: guardian.id,
                  value: guardian.id
                }, _toDisplayString(guardian.firstname + ' '+guardian.lastname), 9, _hoisted_13))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_UiButton, {
            class: "px-4 real-estate-page--new-button bg-mediumGrey text-base text-white hover:bg-darkBlue",
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('FILTER_INPUT_BUTTON')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ], 32))
}