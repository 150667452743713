
import { defineComponent, reactive, watch, PropType, ref } from 'vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
  Field
} from 'vee-validate'
import { SpacesItem } from '@/store/space/types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ContactForm',
  props: {
    activeTab: {
      type: Number,
      default: () => 0,
    },
    isUpdate: {
      type: Boolean,
      default: () => false,
    },
    residenceList: {
      type: Object as PropType<SpacesItem[]>,
      default: () => ({}),
    },
    asteriskOnRequired: {
      type: Boolean,
      default: false,
    },
    realEstateData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', parent: '' }),
    },
    personalData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', roles: ['ROLE_USER'], active: false }),
    },
  },
  components: {
    Field,
  },
  emits: ['onBuildingFormSubmit'],
  setup(props, { emit }) {

    let isNotAddressDifferent = ref(true)
    const stairsList = ref(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'])

    const { t } = useI18n()
    const { handleSubmit } = useForm({
      validationSchema: {
        residence: 'required',
        address: 'required',
        city: 'required',
        name: 'required',
        levelMin: 'required',
        levelMax: 'required',
        stairsMin: 'required',
        stairsMax: 'required',
        zipCode: `min:5|numeric|required`,
      },
    })

    const form = reactive({
      address: useField('address', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_LOCATION_ADDRESS'),
      }),
      name: useField('name', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_MAIN_LABEL_BUILDING'),
      }),
      zipCode: useField('zipCode', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_LOCATION_POSTALCODE'),
      }),
      city: useField('city', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_LOCATION_CITY'),
      }),
      entryCode1: useField('entryCode1', '', {
        label: t('REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_MORNING_CODE_01'),
      }),
      entryCode2: useField('entryCode2', '', {
        label: t('REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_MORNING_CODE_02'),
      }),
      levelMin: useField('levelMin', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_FLOOR_MIN'),
      }),
      levelMax: useField('levelMax', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_FLOOR_MAX'),
      }),
      stairsMin: useField('stairsMin', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_STAIRCASE_MIN'),
      }),
      stairsMax: useField('stairsMax', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_STAIRCASE_MAX'),
      }),
      isAddressDifferent: useField('isAddressDifferent', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_IS_ADDRESS_DIFFERENT'),
        type: 'checkbox',
        valueProp: 'Checkbox value'
      }),
      residence: useField('residence', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_MAIN_LABEL_RESIDENCE'),
      })
    })
    const value = ref(form.residence.value)

    //To set initial Values of the fields, because initialValue in useField doesn't work as expected
    watch(
      () => props.realEstateData,
      (newVal) => {
        form.address.setValue(newVal.address)
        form.name.setValue(newVal.build)
        form.zipCode.setValue(newVal.zipCode)
        form.city.setValue(newVal.city)
        if (newVal.parent) {
        form.residence.setValue(newVal.parent.id)
        }
        form.entryCode1.setValue(newVal.entryCode1)
        form.entryCode2.setValue(newVal.entryCode2)
        form.levelMin.setValue(newVal.levelMin)
        form.levelMax.setValue(newVal.levelMax)
        form.stairsMin.setValue(newVal.stairsMin)
        form.stairsMax.setValue(newVal.stairsMax)
        if (newVal.isAddressDifferent) {
          form.isAddressDifferent.setValue(newVal.isAddressDifferent)
        }
      },
      { immediate: true }
    )

    const onSubmit = handleSubmit(
      async ({ residence, address, name, zipCode, city, entryCode1, entryCode2, levelMin, levelMax, stairsMin, stairsMax }) => {
        let isAddressDifferent = true
        if (form.isAddressDifferent.value === undefined || form.isAddressDifferent.value.length < 1) {
          isAddressDifferent = false
        }
        const company:any = props.residenceList.find(res => res.id == residence)?.company
        emit('onBuildingFormSubmit', { residence, address, name, zipCode, entryCode1, entryCode2, levelMin, levelMax, stairsMin, stairsMax, isAddressDifferent, city, id: props.realEstateData.id, realEstateData: props.realEstateData, type: "batiment",company:company?.id, userOrganization: props.personalData.userOrganization })
      }
    )

    const setAddress = () => {
      console.log(form.isAddressDifferent.value)
      if (form.isAddressDifferent.value === undefined || form.isAddressDifferent.value.length < 1) {
        const residenceId: any = form.residence.value
        const residence: any = props.residenceList.find(residence => residence.id == residenceId.value)
        form.zipCode.setValue(residence.zipCode)
        form.city.setValue(residence.city)
        form.address.setValue(residence.address)
      }
    }
    const setIsAdressDifferent = () => {
      if (form.isAddressDifferent.value === undefined || form.isAddressDifferent.value.length < 1) {
        isNotAddressDifferent.value = true
        setAddress();
      }
      else {
        isNotAddressDifferent.value = false
      }
    }

    const getInitSelected = (residenceId: any) => {
      const residence: any = form.residence.value
      console.log("fresidence.value?.id", residence.value?.id)
      console.log("residenceId", residenceId)
      if (residenceId === residence.value?.id) {
      console.log("coucou", residenceId)
        return true
      }
      else {
        return false
      }
    }
    return {
      onSubmit,
      getInitSelected,
      setAddress,
      setIsAdressDifferent,
      value,
      isNotAddressDifferent,
      stairsList,
      form,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
    }
  },
})
