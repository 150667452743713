import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "real-estate-page--modal-container-header" }
const _hoisted_2 = { class: "real-estate-page--modal-container-content" }
const _hoisted_3 = { class: "flex mx-5 my-3 space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_RealEstateBuildingForm = _resolveComponent("RealEstateBuildingForm")!
  const _component_UiModal = _resolveComponent("UiModal")!

  return (_openBlock(), _createBlock(_component_UiModal, {
    class: "real-estate-page--modal",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
    onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
    onDrop: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "real-estate-page--modal-container",
        onDrop: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragFile && _ctx.dragFile(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('REAL_ESTATE_MODAL_HEADER')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RealEstateBuildingForm, {
            asteriskOnRequired: true,
            onOnBuildingFormSubmit: _ctx.onBuildingFormSubmit,
            personalData: _ctx.personalData,
            residenceList: _ctx.residenceList
          }, {
            buttons: _withCtx(({ isSubmitting, isValid }) => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_UiButton, {
                  class: "py-3 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('REAL_ESTATE_MODAL_BUTTONS_CANCEL')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_UiButton, {
                  class: "w-full py-3 bg-blue hover:bg-darkBlue text-white",
                  loading: isSubmitting,
                  type: "submit",
                  disabled: !isValid
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('REAL_ESTATE_MODAL_BUTTONS_SUBMIT')), 1)
                  ]),
                  _: 2
                }, 1032, ["loading", "disabled"])
              ])
            ]),
            _: 1
          }, 8, ["onOnBuildingFormSubmit", "personalData", "residenceList"])
        ])
      ], 32)
    ]),
    _: 1
  }))
}