
import { defineComponent, reactive, watch } from 'vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { MIN_PHONE_LENGTH } from '@/constants'

export default defineComponent({
  name: 'ContactForm',
  props: {
    activeTab: {
      type: Number,
      default: () => 0,
    },
    asteriskOnRequired: {
      type: Boolean,
      default: false,
    },
    realEstateData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', parent: '' }),
    },
    personalData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', roles: ['ROLE_USER'], active: false }),
    },
    modalCompanyList: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['onUpdateResidenceFormSubmit'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { handleSubmit } = useForm({
      validationSchema: {
        address: 'required',
        residenceName: 'required',
        zipCode: `min:5|numeric`,
      },
    })

    const form = reactive({
      address: useField('address', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_LOCATION_ADDRESS'),
      }),
      residenceName: useField('residenceName', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_MAIN_LABEL_RESIDENCE'),
      }),
      zipCode: useField('zipCode', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_LOCATION_POSTALCODE'),
      }),
      city: useField('city', '', {
        label: t('REAL_ESTATE_MODAL_CONTENT_LOCATION_CITY'),
      }),
      entryCode1: useField('entryCode1', '', {
        label: t('REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_MORNING_CODE_01'),
      }),
      entryCode2: useField('entryCode2', '', {
        label: t('REAL_ESTATE_ADDITIONAL_ACCESS_MORNING_BLOCK_LABEL_MORNING_CODE_02'),
      }),
      company: useField('company', '', {
        label: t('CONTACT_MY_INFORMATION_PHONE_FIELD_LABEL'),
      })
    })

    //To set initial Values of the fields, because initialValue in useField doesn't work as expected
    watch(
      () => props.realEstateData,
      (newVal) => {
        if(newVal.parent)
        {form.address.setValue(newVal.parent.address)
        form.residenceName.setValue(newVal.parentName)
        form.zipCode.setValue(newVal.parent.zipCode)
        form.city.setValue(newVal.parent.city)
        form.company.setValue(newVal.parent.company)
        form.entryCode1.setValue(newVal.parent.entryCode1)
        form.entryCode2.setValue(newVal.parent.entryCode2)
        }
      },
      { immediate: true }
    )

    const onSubmit = handleSubmit(
      async ({ address, residenceName, zipCode, city, entryCode1, entryCode2, company}) => {
        emit('onUpdateResidenceFormSubmit', { address, name: residenceName, zipCode, entryCode1, entryCode2, city, company: company, id: props.realEstateData.parent.id, realEstateData: props.realEstateData,  })
      }
    )

    return {
      onSubmit,
      form,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
    }
  },
})
