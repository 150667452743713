
import { defineComponent, computed, ref } from 'vue'
import { useCancelToken } from '@/hooks/useCancelToken'
import useStore from '@/store'
import { SPACE_ACTION_TYPES } from '@/store/space/actions'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import ViewLayout from '@/components/particles/common/ViewLayout.vue'
import Filter from '@/components/particles/RealEstateView/Filter.vue'
import { realEstateTableHeaders } from '@/helpers/tableHeaders'
import RealEstateTable from '@/components/particles/RealEstateView/RealEstateTable.vue'
import RealEstateBuildingAddModal from '@/components/particles/RealEstateView/RealEstateBuildingAddModal.vue'
import RealEstateBuildingUpdateModal from '@/components/particles/RealEstateView/RealEstateBuildingUpdateModal.vue'
import RealEstateResidenceUpdateModal from '@/components/particles/RealEstateView/RealEstateResidenceUpdateModal.vue'
import { createToast } from 'mosha-vue-toastify';
import { SpacesItem } from '@/store/space/types'

export default defineComponent({
  name: 'RealEstateView',
  components: {
    Filter,
    LoadingSpinner,
    ViewLayout,
    RealEstateTable,
    RealEstateBuildingAddModal,
    RealEstateBuildingUpdateModal,
    RealEstateResidenceUpdateModal,
  },
  setup() {
    const store = useStore()
    const getSpacesCancelToken = useCancelToken()
    const getPersonalInfoCancelToken = useCancelToken()
    const headerType = ref()
    const headerStatus = ref()
    const headerBattery = ref()
    const perPage = ref()
    const filterString = ref('')
    const realEstateData = ref()
    let isShowUpdateBuildingModal = ref(false)
    let isShowUpdateResidenceModal = ref(false)
    let isShowAddModal = ref(false)

    
    const spaces = computed(() => store.state.space.spaces)
    const managerFilter = computed(() => store.state.space.managerFilter)
    const guardianFilter = computed(() => store.state.space.guardianFilter)
    const companyFilter = computed(() => store.state.space.companyFilter)
    const residenceFilter = computed(() => store.state.space.residenceFilter)
    const residenceList = computed(() => store.state.space.residenceList)
    const isLoaded = computed(() => store.state.realEstate.isLoaded)
    const personalData = computed(() => store.state.userSettings.personalInfo)


    const getSpaces = async () => {
      try {
        await Promise.all([
          store.dispatch(
            SPACE_ACTION_TYPES.GET_SPACES,
            getSpacesCancelToken.value
          ),
        ])
      } catch (error) {
        console.log('error: ', error)
      }
    }

    const onUpdateResidenceFormSubmit = async (updatedSpace: SpacesItem) => {
      await store.dispatch(
        SPACE_ACTION_TYPES.PATCH_ONE_SPACES,
        updatedSpace
      )
      toggleUpdateResidenceModal({})
      getSpaces()
      createToast({
        title: "La résidence a été modifié",
      },
        {
          type: 'success',
          position: 'bottom-right',
          showIcon: true,
        })
    }
    const onAddBuildingFormSubmit = async (addSpace: SpacesItem) => {
      await store.dispatch(
        SPACE_ACTION_TYPES.POST_SPACES,
        addSpace
      )
      toggleAddModal()
      getSpaces()
      createToast({
        title: "Le batiment a été ajouté",
      },
        {
          type: 'success',
          position: 'bottom-right',
          showIcon: true,
        })
    }

    const onUpdateBuildingFormSubmit = async (updatedSpace: SpacesItem) => {
      await store.dispatch(
        SPACE_ACTION_TYPES.PATCH_ONE_SPACES,
        updatedSpace
      )
      toggleUpdateBuildingModal({})
      getSpaces()
      createToast({
        title: "Le batiment a été modifié",
      },
        {
          type: 'success',
          position: 'bottom-right',
          showIcon: true,
        })
    }
    const getPersonalInfo = async () => {
      await store.dispatch(
        USER_SETTINGS_ACTION_TYPES.GET_PERSONAL_INFO,
        getPersonalInfoCancelToken.value
      )
    }
    getPersonalInfo()
    getSpaces()

    const toggleAddModal = () => {
      isShowAddModal.value = !isShowAddModal.value
    }
    const toggleUpdateBuildingModal = (realEstate: any) => {
      realEstateData.value = realEstate
      isShowUpdateBuildingModal.value = !isShowUpdateBuildingModal.value
    }
    const toggleUpdateResidenceModal = (realEstate: any) => {
      realEstateData.value = realEstate
      isShowUpdateResidenceModal.value = !isShowUpdateResidenceModal.value
    }

    const handleFilter = async (filter: any) => {
      try {
        await Promise.all([
          store.dispatch(
            SPACE_ACTION_TYPES.GET_SPACES_FILTER,
            filter
          ),
        ])
      } catch (error) {
        console.log('error: ', error)
      }

    }

    return {
      isLoaded,
      spaces,
      headerType,
      headerStatus,
      headerBattery,
      perPage,
      realEstateTableHeaders,
      filterString,
      isShowAddModal,
      isShowUpdateBuildingModal,
      isShowUpdateResidenceModal,
      toggleAddModal,
      toggleUpdateBuildingModal,
      toggleUpdateResidenceModal,
      onUpdateResidenceFormSubmit,
      onUpdateBuildingFormSubmit,
      onAddBuildingFormSubmit,
      realEstateData,
      personalData,
      managerFilter,
      guardianFilter,
      companyFilter,
      residenceFilter,
      residenceList,
      handleFilter
    }
  },
})
