
import { defineComponent, PropType, ref } from 'vue'
import { SpacesItem } from '@/store/space/types'
import { cloneDeep } from 'lodash'

export default defineComponent({
  props: {
    tableRow: {
      type: Object as PropType<SpacesItem>,
      default: () => ({}),
    },
  },
  emits: [
    'onUpdateBuildingRealEstateInfo',
    'onUpdateResidenceRealEstateInfo'
  ],
  setup(props, { emit }) {
    const data = ref<SpacesItem>({ ...props.tableRow })
    const formData = cloneDeep(data);

    const onUpdateBuildingRealEstateInfo = (realBuildingEstateInfo: any) => {
      emit('onUpdateBuildingRealEstateInfo', realBuildingEstateInfo)
    }
    const onUpdateResidenceRealEstateInfo = (realResidenceEstateInfo: any) => {
      emit('onUpdateResidenceRealEstateInfo', realResidenceEstateInfo)
    }

    return {
      data,
      formData,
      onUpdateBuildingRealEstateInfo,
      onUpdateResidenceRealEstateInfo
    }
  }
})
