
import { defineComponent, PropType } from 'vue'
import Papa from 'papaparse'
import RealEstateBuildingForm from '@/components/particles/forms/RealEstateForm/RealEstateBuildingForm.vue'
import { SpacesItem } from '@/store/space/types'

export default defineComponent({
  props: {
    personalData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', roles: ['ROLE_USER'], active: false }),
    },
    residenceList: {
      type: Object as PropType<SpacesItem[]>,
      default: () => ({}),
    },
  },
  emits: ["close", 'onBuildingFormSubmit'],
  setup(props, { emit }) {
    const onBuildingFormSubmit = (realEstateBuilding: any) => {
      emit('onBuildingFormSubmit', realEstateBuilding)
    }
    const dragFile = (e: any) => {
      Papa.parse(e.dataTransfer.files[0], {
        complete: (results: any) => {
          if (!results.errors.length) {
            // save imported data
            // console.info(results.data);
          }
        },
      });
    };
    return {
      dragFile,
      onBuildingFormSubmit,
    };
  },
  components: { RealEstateBuildingForm }
})
