
import { defineComponent, PropType } from 'vue'
import { SpacesItem } from '@/store/space/types'
import RealEstateTableHiddenBlock from '@/components/particles/RealEstateView/RealEstateTableHiddenBlock.vue'

export default defineComponent({
  components: {
    RealEstateTableHiddenBlock,
  },
  props: {
    tableInfo: {
      type: Array as PropType<SpacesItem[]>,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerType: {
      type: String,
      required: true,
      default: '',
    },
    headerStatus: {
      type: Function,
      required: true,
      default: (val: unknown) => val,
    },
    headerBattery: {
      type: Function,
      required: true,
      default: (val: unknown) => val,
    },
    numberOfVisibleRows: {
      type: Number,
      default: 10,
    },
  },
  emits: [
    'update:headerType',
    'update:headerBattery',
    'update:headerStatus',
    'update:numberOfVisibleRows',
    'updateSpace',
    'onUpdateBuildingRealEstateInfo',
    'onUpdateResidenceRealEstateInfo'
  ],
  setup(props, { emit }) {
    

    const showHiddenInfoBlock = (row: SpacesItem) => {
      //Adds an IsOpened Field which is necessary to show/close hidden block in UiTable
      if (row.IsOpened === undefined || row.IsOpened === false)
        row.IsOpened = true
      else row.IsOpened = false
    }

    const onUpdateBuildingRealEstateInfo = (realBuildingEstateInfo: any) => {
      emit('onUpdateBuildingRealEstateInfo', realBuildingEstateInfo)
    }
    const onUpdateResidenceRealEstateInfo = (realResidenceEstateInfo: any) => {
      emit('onUpdateResidenceRealEstateInfo', realResidenceEstateInfo)
    }

    return {
      showHiddenInfoBlock,
      onUpdateBuildingRealEstateInfo,
      onUpdateResidenceRealEstateInfo,
    }
  },
})
