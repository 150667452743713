import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_RealEstateTableHiddenBlock = _resolveComponent("RealEstateTableHiddenBlock")!
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createBlock(_component_UiTable, {
    headers: _ctx.headers,
    data: _ctx.tableInfo,
    "per-page": _ctx.numberOfVisibleRows,
    ref: "realEstateTable"
  }, {
    "cell:details": _withCtx(({ row }) => [
      _createVNode(_component_UiButton, {
        icon: "",
        onClick: ($event: any) => (_ctx.showHiddenInfoBlock(row))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UiIcon, {
            name: "details",
            class: "mr-1.5"
          })
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    rowHiddenInfo: _withCtx(({ row }) => [
      _createVNode(_component_RealEstateTableHiddenBlock, {
        tableRow: row,
        onUpdateSpace: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateSpace', $event))),
        onOnUpdateBuildingRealEstateInfo: _ctx.onUpdateBuildingRealEstateInfo,
        onOnUpdateResidenceRealEstateInfo: _ctx.onUpdateResidenceRealEstateInfo
      }, null, 8, ["tableRow", "onOnUpdateBuildingRealEstateInfo", "onOnUpdateResidenceRealEstateInfo"])
    ]),
    _: 1
  }, 8, ["headers", "data", "per-page"]))
}