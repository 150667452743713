
import { defineComponent, reactive, watch , PropType} from 'vue'
import { FilterCounterInfo } from '@/store/dashboard/types'
import { IContact } from '@/store/contact/types'
import { SpacesItem } from '@/store/space/types'
import { ICompany } from '@/store/company/types'

import {
  useField,
  useForm,
  Field
} from 'vee-validate'

import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Field,
  },
  props: {
    filterCounterInfo: {
      type: Object as PropType<FilterCounterInfo>,
      default: () => ({}),
    },
    residenceFilter: {
      type: Object as PropType<SpacesItem[]>,
      default: () => ({}),
    },
    companyFilter: {
      type: Object as PropType<ICompany[]>,
      default: () => ({}),
    },
    managerFilter: {
      type: Object as PropType<IContact[]>,
      default: () => ({}),
    },
    guardianFilter: {
      type: Object as PropType<IContact[]>,
      default: () => ({}),
    },
  },
  emits: [ 'onFilter'],
  setup(props, { emit }) {
    
    const { t } = useI18n()
    const { handleSubmit } = useForm({
      validationSchema: {
      },
    })
    const form = reactive({
    })

    //To set initial Values of the fields, because initialValue in useField doesn't work as expected
  

    const onSubmit = handleSubmit(
      async ({ company, manager, residence, guardian }) => {
        let filter='?'
        if(manager)
        {
          filter=filter+'manager=/contacts/'+manager+'&'
        }
        if(guardian)
        {
          filter=filter+'guardian=/contacts/'+guardian+'&'
        }
        if(company)
        {
          filter=filter+'company=/companies/'+company+'&'
        }
        if(residence)
        {
          filter=filter+'parent=/spaces/'+residence+'&'
        }
        emit('onFilter', { filter})
      }
    )

    return {
      onSubmit,
      form,
    }
  },
})
